/**
 * Content utility functions
 */

import { isEmpty, generateToken } from 'library/utility/global'
import { filterPathUpdater } from 'library/filters/portfolio'

/**
 * Wrapper function that accepts a Contentful entry and returns the content
 * after running through the dynamic content filter
 * @param  {obj} entry
 * @return {str}
 */
export const filterDynamicContentEntry = (entry = {}) => {
  if (isEmpty(entry)) return
  const content = entry.content ? entry.content : '',
        dynamicContent = entry.dynamicContent ? entry.dynamicContent : []
  return filterDynamicContent(content, dynamicContent)
}

/**
 * Filter through the provided content and replace {{tokens}}
 * with associated content from Contentful and return new content
 * @param  {str} content
 * @param  {arr} dynamicContent
 * @return @return {str}
 */
export const filterDynamicContent = (content = '', dynamicContent = []) => {

  // Dynamic content required
  if (isEmpty(dynamicContent)) return content

  // Set up mapping
  let placeholderMap = {}

  // Tokens whose condition are not in this array will be removed
  const conditions = ['Any', 'Web']

  // Loop through each dynamic content object and build mapping
  for (let i = 0, len = dynamicContent.length; i < len; i++) {
    if (!dynamicContent[i].condition && !dynamicContent[i].content) continue
    
    // Empty values are used when conditions don't match
    let value = ''

    // Populate when values avilable
    if (conditions.includes(dynamicContent[i].condition) && dynamicContent[i].content.content ) {
      value = dynamicContent[i].content.content
    }        

    // Compile map
    placeholderMap = {
      ...placeholderMap,
      [dynamicContent[i].slug]: value
    }

  }

  // Run replacement function
  return replacePlaceholders(content, placeholderMap)

}

/**
 * Replace the string {{token}} matches if they are a part of the mapping
 * Non-mapped matches are returned, assumed intentional
 * @param  {str} content
 * @param  {obj} placeholderMap
 * @return {str}
 */
export const replacePlaceholders = (content = '', placeholderMap = {}) => {

  // Mapping required
  if (isEmpty(placeholderMap)) return content

  // First pass replaces single line {{tokens}} and removes wrapping <p> tags from editor
  // Example: <p>{{token}}</p>
  content = content.replace(/\<p\>\{\{([^}]+)\}\}\<\/p\>/g, (match) => {
    
    // Strip wrapper
    match = match.slice(5, -6)

    // Find match in mapping and replace if applicable
    let value = ''
    if (placeholderMap.hasOwnProperty(match)) {
      value = placeholderMap[match]
    } else {
      return `<p>{{${match}}}</p>`
    }

    return value

  })

  // Second pass replaces inline {{tokens}}
  // Example: <p>Lorem ipsum {{token}} dolor set amet...</p>
  content = content.replace(/\{\{([^}]+)\}\}/g, (match) => {

    // Strip wrapper
    match = match.slice(2, -2)
    
    // Find match in mapping and replace if applicable
    let value = ''
    if (placeholderMap.hasOwnProperty(match)) {
      value = placeholderMap[match]
    } else {
      return `{{${match}}}`
    }
    return value
  })

  return content

}

/**
 * Filter content for specific content wrappers and extract to a replacement map
 * [[lifetime-only-start]], [[lifetime-only-end]]
 * @param  {str} content
 * @return {arr}
 */
export const extractLifetimeContentToMap = (content = '') => {

  // First pass replaces single line [[tokens]] and removes wrapping <p> tags from editor
  // Example: <p>[[token]]</p>
  content = content.replace(/\<[^>]*\>\[\[([^\]]+)\]\]\<\/[^>]*\>/g, (match) => {
    return match.slice(3, -4)
  })

  // Extract blocks of wrapped content
  // Keeping this regex that uses lookbehind - Apple doesn't support yet
  // const wrappedItems = content.match(/(?<=\[\[lifetime-only-start\]\]\s+).*?(?=\s+\[\[lifetime-only-end\]\])/gs)
  const wrappedItems = content.match(/(?:\[\[lifetime-only-start\]\]\s+).*?(?=\s+\[\[lifetime-only-end\]\])/g)

  // Assigned found content to temp map for replacement
  // Replace extracted content with temp token
  let replacementMap = {}
  if (wrappedItems) {
    for (let item of wrappedItems) {

      // Remove beginning tag to compenstate for Apple lack of support for lookbehind
      item = item.replace(/\[\[lifetime-only-start\]\]\n/g, '')

      const token = generateToken()
      replacementMap[token] = item
      content = content.replace(item, `{{${token}}}`)
    }
  }

  // Remove lifetime content from content & return
  content = content.replace(/\[\[lifetime-only-start\]\]/g, '')
  content = content.replace(/\[\[lifetime-only-end\]\]/g, '')
  return [ content, replacementMap ]

}

/**
 * Replace portfolio data items with placeholders
 * Includes wrapping some items with links/params
 * @param  {str} string
 * @param  {obj} object
 * @return {arr} [str, boo]
 */
export const replacePortfolioPlaceholders = (string, object) => {

  // Set render flag
  let render = false

  // Find all {{tokens}}
  if (string) {
    string = string.replace(/\{\{([^}]+)\}\}/g, (match) => {

      // Remove wrapping brackets
      match = match.slice(2, -2)

      // Extract link param if set in string
      const parts = match.split(' link=')
      if (parts && parts[1]) {

        // Set parts to new vars
        let token = parts[0]
        let linkText = parts[1]
        render = true

        // Filter path
        if (object[token]) object[token] = filterPathUpdater(object[token])

        // Remove quotes from param
        if (linkText) linkText = linkText.slice(1, -1)

        // Build link as string
        return `<a href="{{${token}}}" target="_blank">${linkText}</a>`

      } else {
        return `{{${match}}}`
      }
    })

    // Do regular replace
    string = replacePlaceholders(string, object)
  }

  // Return string with render flag
  return [string, render]

}

/**
 * Get the first accessible subscription in sequential array and return
 * @param  {arr}  subscriptions
 * @param  {func} hasAccess
 * @return {obj}
 */
import { useContext } from 'react'
import { UserContext } from 'library/context/userContext'

export const getPrimarySubscription = (subscriptions = []) => {

  // Only applies to multiple subscriptions
  if (subscriptions.length === 0) return null
  if (subscriptions.length === 1) return subscriptions[0]

  // Auth function from user state
  const { userHasAccess } = useContext(UserContext)

  // Find first hit
  let getPrimarySubscription = {}
  for (const sub of subscriptions) {
    if (userHasAccess(sub, ['subscriptionView'])) {
      getPrimarySubscription = sub
      break
    }
  }

  // Return primary sub
  return getPrimarySubscription
}

/**
 * Convert rich text content to HTML in single entry or array of entries
 * @param  {arr|obj} data
 * @return {arr|obj}
 */
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

export const replaceContentWithRichText = (data) => {

  // Required
  if (!data || isEmpty(data)) return data

  // Rich text render options
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        let caption = ''
        if (node.data.target.description) caption = `<p class="richtext-caption">${node.data.target.description}</p>`
        return `
          <img
            src="https:${node.data.target.file.url}"
            width="${node.data.target.file.details.image.width}"
            height="${node.data.target.file.details.image.height}"
            title="${node.data.target.title}"
            alt="${node.data.target.title}"
            class="richtext-image"
          />
          ${caption}
        `
      }
    },
    preserveWhitespace: true
  }

  // If an array, loop through each entry and apply if applicable
  if (Array.isArray(data)) {
    for (const item of data) {
      if (item.contentRichText) item.content = documentToHtmlString(item.contentRichText, options)
    }

  // If single entry
  } else if (data.contentRichText) {
    data.content = documentToHtmlString(data.contentRichText, options)
  }

  return data
}